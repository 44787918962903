import React from "react";

import Layout from "../layout/Layout";

import Seo from "../components/seo";
import Events from "../components/sections/Events";

const EventsPage = () => {
  return (
    <Layout>
      <Seo title="About" />
      <Events />
    </Layout>
  );
};

export default EventsPage;
