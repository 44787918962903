import React from "react"

import Seo from "../../../components/seo"

import {
  CardHorizontalEvents,
  CardVerticalEvents,
} from "../../../components/Cards"
import { StaticImage } from "gatsby-plugin-image"
import { IconMailSplash } from "../../../atoms/Icons"
import useEvents from "../../../utils/useEvents"

const Events = () => {
  const data = useEvents()

  return (
    <>
      <div className="absolute -top-64 -left-72 scale-75 lg:scale-100">
        <StaticImage
          height={576}
          src="../../../images/BackgroundElements/Home/Lemondrop-01.png"
          placeholder="tracedSVG"
          alt="lemon"
        />
      </div>

      <Seo title="Events" />
      <div className="relative flex flex-col gap-8">
        <h1 className="lg:hidden mobile-title pt-8 text-center">
          Upcoming Events
        </h1>
        <h1 className="hidden lg:block desktop-title text-center pt-8">
          Workshops & Upcoming Events
        </h1>
        <div className="max-w-mobile pl-2 lg:max-w-6xl text-center mobile-paragraph lg:desktop-paragraph mx-auto">
          <p>
            I am available for private and group live or online workshops,
            Personalised Pancha Karma Retreats and Private Mentorship, based
            upon the 4 Pillars. Please call or email.
          </p>
        </div>
        <div className="pt-4 lg:pt-0">
          <div className="xl:hidden overflow-x-scroll snap-x flex items-center mx-auto gap-8">
            <div className="mx-auto space-y-8">
              {data.map(i => (
                // <Link to={`/events/${i.slug}`}>
                <CardVerticalEvents
                  title={i.title}
                  heading={i.heading}
                  image={i.image}
                  body={i.body}
                  slug={i.slug}
                />
                // </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="hidden  max-w-7xl xl:grid grid-cols-2 place-content-center gap-8 mx-auto px-2">
            {data.map(i => (
              // <Link to={`/events/${i.slug}`}>
              <CardHorizontalEvents
                title={i.title}
                heading={i.heading}
                image={i.image}
                body={i.body}
                slug={i.slug}
              />
              // </Link>
            ))}
          </div>
        </div>

        <div className="  flex flex-col xl:flex-row items-center px-24">
          <div className="lg:w-1/2">
            <h1 className=" mobile-title lg:desktop-title text-center pb-4 pt-8">
              The Venus Project
            </h1>
            <div className="px-5 text-center lg:text-left mobile-paragraph lg:desktop-paragraph max-w-xl mx-auto flex flex-col">
              <p>
                The Birth of <strong>the Venus Projects</strong> came of
                listening to my Latin girlfriend’s chat together with wide open
                mouths and voices, while within the safety of the sisterhood,
                but when they stepped before a crowd or into their own homes,
                their throats tightened, and they became a mere whisper.
              </p>
              <p>
                Being an American woman raised in the 60’s, trained in Theatre
                and possessing the MOUTH THAT ROARED, I took it upon myself to
                help my friends open their voices, learn to breathe deeply and
                feel the freedom of expression.
              </p>
              <br />
              <p>
                We have birthed three projects, thus far, and when we can
                gather, in the midst of careers, child rearing and general
                living, we shall create another.
              </p>
              <br />
              <p>
                In the Meantime, I am available to come to your town and put
                together a workshop format, if you are interested, contact me at{" "}
                <strong>madameflore@yahoo.co.uk</strong>
              </p>
            </div>
          </div>

          <div className="w-1/2 mx-auto flex items-center justify-center">
            <div className="pt-8">
              <StaticImage
                height={800}
                wudth={500}
                src="../../../images/BackgroundElements/Workshops/venus.jpg"
                placeholder="tracedSVG"
                alt="venus project flyer"
              />
            </div>
          </div>
        </div>
        <div className="relative   lg:flex bg-secondaryBackground flex-col items-center text-secondary py-16 my-16 px-24 ">
          <div className="text-center flex flex-col items-center gap-2">
            <h2 className="mobile-subtitle lg:desktop-subtitle pb-4">
              Get in touch!
            </h2>
            <p className="max-w-mobile lg:max-w-full  mobile-highlights lg:desktop-highlights text-center">
              I am available to come to your town and put together a workshop
              format to address similar vocal issues and
            </p>
            <p className="max-w-6xl desktop-highlights hidden lg:block">
              explore our creativity and inner messages that need to be birthed!
            </p>
            <div className="flex items-center gap-1 mx-auto">
              <IconMailSplash />
              <p className="cards-cta lg:navbar mt-0.5">
                madameflore@yahoo.co.uk
              </p>
            </div>
          </div>

          <div className="absolute bottom-0 right-0 z-10 hidden lg:block">
            <StaticImage
              height={269}
              src="../../../images/BackgroundElements/Home/Mermaid.png"
              alt="Lemon"
              placeholder="tracedSVG"
            />
          </div>
          <div className="absolute bottom-0 -left-32 z-10 lg:hidden">
            <StaticImage
              height={124}
              src="../../../images/BackgroundElements/Home/LemonFooter.png"
              alt="Lemon"
              placeholder="tracedSVG"
            />
          </div>
        </div>

        <div className="relative flex flex-col gap-6 items-center pb-16">
          <h2 className="mobile-title lg:desktop-title text-center">
            Stay up to date with
            <br className="lg:hidden" /> upcoming events
          </h2>
          <div className="flex items-center ">
            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="suscriptions"
              clsasName="flex flex-col justify-center lg:flex-row items-center"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="suscriptions" />

              <input
                className="mobile-input  lg:desktop-input cards lg:navbar bg-mainBackground text-secondary placeholder-secondary"
                placeholder="Your Email"
                type="email"
                name="email"
                id="email"
              />
              <button
                type="submit"
                className="block mx-auto lg:inline button-primary lg:ml-8 py-0 text-center mt-8 lg:mt-0"
              >
                Suscribe
              </button>
            </form>
          </div>

          <div className="absolute bottom-0 right-0 lg:hidden">
            <StaticImage
              height={224}
              src="../../../images/BackgroundElements/Home/Mermaid.png"
              placeholder="tracedSVG"
              alt="mermaid"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Events
